@import "./partials/variables";
@import "./partials/mixins";
@import "./partials/classes";

iframe {
  display: none;
}

@media (min-width: 992px) {
  .position-md-absolute {
    position: absolute;
  }
}

.pb-6 {
  padding-bottom: 4rem;
}

// navbar.....
.container-fluid {
  padding: 0 40px;
  box-sizing: border-box;
  @media (max-width: 600px) {
    padding: 0 12px;
  }
}
a {
  color: $text-color;
  &:hover {
    color: $text-color;
  }
}
body {
  color: $text-color;
}
p.sub-title {
  font-size: 16px;
  width: 78%;
  margin-top: 18px;
  opacity: 0.8;
}
h4.sub-Title {
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  color: #000f3c;
}
.header-title.about-title h2 {
  width: 663px !important ;
  @media (max-width: 1280px) {
    font-weight: 700;
    font-size: 45px;
    line-height: 45px;
    color: #000f3c;
  }
  @media (max-width: 992px) {
    width: 100% !important;
    margin: auto;
    text-align: center;
  }
  @media (max-width: 678px) {
    width: 200px !important;
    font-size: 35px !important;
  }
}
h4.sub-Title.about-sub-Title {
  width: 604px;
  @media (max-width: 1280px) {
    font-weight: 500;
    width: 418px;
    font-size: 25px;
    line-height: 35px;
  }
  @media (max-width: 992px) {
    margin: auto;
    text-align: center;
  }
  @media (max-width: 678px) {
    width: 264px;
    font-size: 20px;
  }
}
p.sub-Title.about-sub-Title {
  width: 617px;
  @media (max-width: 1280px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
  }
  @media (max-width: 992px) {
    margin: auto;
    text-align: center;
  }
  @media (max-width: 678px) {
    width: 260px;
    font-size: 15px;
  }
}
.about-header-rightside {
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-top: 100px;
  & > img {
    width: 533.49px;
    height: 364.18px;
    // position: absolute;
    bottom: 0;
    right: 0;
    @media (max-width: 1280px) {
      width: 360.43px;
      height: 246.04px;
    }
    @media (max-width: 600px) {
      display: none;
      width: 256.42px;
      height: 175.04px;
    }
  }
  @media (max-width: 600px) {
    display: none;
  }
}

h2.main-title {
  font-weight: 600;
  font-size: 35px;
  color: #000f3c;
  text-transform: capitalize;
  @media (max-width: 600px) {
    font-size: 25px;
    line-height: 25px;
  }
  br {
    @media (max-width: 676px) {
      display: none;
    }
  }
}
button.main-btn {
  @include button($button-bg1, $light-color);
  a {
    color: inherit;
    text-decoration: none;
  }
}
button.add-founder {
  @include button($button-bg1, $light-color);
  a {
    color: inherit;
    text-decoration: none;
  }
  &:hover {
    background: #ffffff;
    box-shadow: 0px 0px 5px 3px rgba(88, 128, 255, 0.15);
    color: #09216a !important;
  }
}
button.main-btn2 {
  @include button($button-bg2, $light-color);
  a {
    color: inherit;
    text-decoration: none;
  }
  &:hover {
    background: #ffffff;
    box-shadow: 0px 0px 5px 3px rgba(88, 128, 255, 0.15);
    color: #211e60 !important;
  }
}
button.main-btn-soon {
  @include button($light-color, #000f3c);
  box-shadow: 0px 4px 10px 10px rgba(87, 128, 255, 0.1);
  border-radius: 30px;
  a {
    color: inherit;
    text-decoration: none;
  }
}

img {
  width: 100%;
}

.navbar {
  background: $main-color;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 60px 0px;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 22px 0;

  @media (max-width: 768px) {
    padding: 9px 0;
    border-radius: 0;
  }

  .container-fluid {
    padding: 0 150px;

    @media (max-width: 1280px) {
      padding: 0 50px;
    }
    @media (max-width: 768px) {
      padding: 0 25px;
    }
  }
  .navbar-brand {
    position: relative;
    @media (max-width: 580px) {
      width: 35%;
    }
    @media (max-width: 600px) {
      width: 50%;
      margin-right: 0;
    }
    @media (max-width: 375px) {
      width: 50%;
      margin-right: 0;
    }
    img {
      &:first-child {
        display: block;
      }
      &:last-child {
        display: none;
      }
    }
  }
  .navbar-div {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 70%;
    @media (max-width: 992px) {
      width: 50%;
    }
    .nav-link {
      margin-right: 30px;
    }
  }

  .mobile-show-navbar {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 30px 14px;
    display: none;

    @media (max-width: 992px) {
      display: flex;
    }
  }

  ul {
    li {
      &:first-child {
        display: none;
        @media (max-width: 992px) {
          display: block;
        }
      }
      a {
        font-weight: 500;
        font-size: 18px;
        margin-right: 18px;
        line-height: 18px;
        color: $light-color !important;
      }
      &:hover a {
        color: #a6baf9 !important;
        @media (max-width: 992px) {
          color: #ff2853 !important;
        }
      }
    }
    li.active a {
      color: #a6baf9 !important;
      @media (max-width: 992px) {
        color: #bacbff !important;
      }
    }
  }
  button.main-btn {
    font-size: 16px;
    &:hover {
      background: transparent;
      box-shadow: 0 0 1px 2px #5780ff;
      font-weight: 600;
    }

    @media (max-width: 992px) {
      font-size: 16px;
      line-height: 16px;
    }
    @media (max-width: 580px) {
      font-size: 12px;
      line-height: 12px;
      padding: 12px 18px;
      margin-top: -5px;
      img {
        width: 29px;
      }
    }
    @media (max-width: 368px) {
      padding: 8px 7px;
    }
    @media (max-width: 285px) {
      padding: 8px 6px;
    }
  }
  button.menu-btn {
    margin-left: 35px;
    border: none;
    background: transparent;
    display: none;
    img {
      &:first-child {
        display: block;
      }
      &:last-child {
        display: none;
      }
    }
    @media (max-width: 580px) {
      margin-left: 8px;
    }
    img {
      @media (max-width: 580px) {
        width: 21px;
      }
    }
  }
  .navbar-menu {
    transition: all 0.5s ease-in-out;
    height: inherit;
    opacity: 1;
    visibility: visible;
    margin-right: 60px;

    button {
      width: 20px;
      border: none;
      background: transparent;
      display: none;
      img {
        width: 100%;
      }
    }
  }
  .navbar-menu:not(.active) {
    @media (min-width: 992px) {
      .separator {
        width: 1px;
        height: 57px;
        background-color: #95a3ce;
        margin-left: 100px;
      }

      .login {
        border: 2px solid #ffffff;
        border-radius: 10px;
        width: 92px;
        height: 38px;
        margin-right: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 50px;

        a {
          margin-right: 0;
        }
      }

      .sign-up {
        width: 92px;
        height: 38px;
        text-align: center;
        background: #ff2853;
        border-radius: 10px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s ease-in;

        &:hover {
          background: white;
          box-shadow: 0 0 13px 3px rgba(88, 128, 255, 0.2);
          color: #ff2853;

          &:hover a {
            color: #ff2853 !important;
          }
        }

        a {
          margin-right: 0;
        }
      }
    }
  }

  .only-desktop {
    @media (max-width: 992px) {
      display: none;
    }
  }

  @media (max-width: 992px) {
    button.main-btn {
      margin-left: 0px;
    }
    button.menu-btn {
      display: block;
    }
    button {
      display: block !important;
    }
    .navbar-menu {
      overflow: hidden;
      height: 0;
      display: block !important;
      opacity: 1;
      visibility: visible;
      transition: all 0.5s ease-in-out;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(179.39deg, #2d4798 0.43%, #09216a 99.47%);
      z-index: 9999;
      ul {
        li {
          a {
            font-size: 34px;
            padding: 32px 0;
            margin: 10px 0;
          }
        }
      }
    }
    .navbar-menu.active {
      height: 100%;
      .avatar {
        display: none;
      }
    }
  }
}

.navbar.sticky {
  background: $light-color;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  animation: navbarAnimation 0.5s ease-in-out;

  .avatar {
    .text {
      color: #000f3b;
    }
  }
  .navbar-brand {
    img {
      &:first-child {
        display: none;
      }
      &:last-child {
        display: block;
      }
    }
  }
  .login {
    border-color: #09216a !important;
  }

  ul {
    li:not(.sign-up) {
      a {
        color: #09216a !important;
        @media (max-width: 991px) {
          color: #fff !important;
        }
      }
      &:hover a {
        color: #ff2853 !important;
      }
    }
    li.active a {
      color: #a6baf9 !important;
      @media (max-width: 992px) {
        color: #bacbff !important;
      }
    }
  }
  button.main-btn {
    background: #5780ff;
    &:hover {
      background: transparent;
      box-shadow: 0 0 1px 2px #5780ff;
      font-weight: 600;
      color: #09216a !important;
    }
  }
  button.menu-btn {
    img {
      &:first-child {
        display: none;
      }
      &:last-child {
        display: block;
      }
    }
  }
}

.header.about-header {
  padding-top: 127px;
  // padding-bottom: 116px;
  height: auto;
  @media (max-width: 991px) {
    // height: 130vh;
    // padding-top: 0px;
    // margin-top: -100px;
  }
  @media (max-width: 600px) {
    img {
      visibility: hidden;
    }
    padding-top: 0px;
    // margin-top: -100px;
  }
  @media (max-width: 375px) {
    padding-top: 0px;
    // margin-top: -100px;
  }
}
.header {
  // padding-top: 120px;
  background: linear-gradient(101.01deg, #d3deff -21.7%, $light-color 46.47%);
  position: relative;
  overflow: hidden;
  .container-fluid {
    padding: 112px 40px 37px 40px;
  }
  .row {
    margin-top: 112px;
  }
  .header-leftside {
    padding-bottom: 70px;
    height: 100%;
    // margin-left: 13%;
    @media (max-width: 992px) {
      // margin-left: 7%;
    }
    @media (max-width: 580px) {
      // margin-left: 0%;
    }
    @extend .flex-basic;
    .header-search {
      width: 100%;
      @extend .flex-basic;
      width: 100%;
      justify-content: flex-start;
      margin-top: 32px;
      flex-direction: column;
      align-items: start;
      @media (max-width: 600px) {
        flex-wrap: wrap;
        margin-bottom: 5px;
        flex-direction: column;
        align-items: start;
      }
    }
    .header-title {
      width: 100%;
    }
    h2 {
      font-weight: 700;
      font-size: 50px;
      position: relative;
      width: 495px;
      margin-bottom: 15px;
      @media (min-width: 1280px) {
        font-size: 60px;
      }
      @media (max-width: 600px) {
        font-size: 30px;
        width: 270px;
        margin-bottom: 18px;
      }
      @media (max-width: 275px) {
        width: auto;
        margin-bottom: 18px;
      }

      img {
        width: auto;
        position: absolute;
        top: -11%;
        left: -12%;
        @media (max-width: 600px) {
          display: none;
        }
      }
    }
    p {
      width: 475px;
      font-family: "Sofia Pro";
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #000f3c;
      @media (max-width: 580px) {
        width: auto;
      }
    }
    input {
      @extend .input-basic;
      border-radius: 5px !important;
      padding: 10px 18px !important;
      margin-bottom: 12px !important;
      height: 56px;
      font-weight: 400;
      font-size: 18px;
      &::placeholder {
        color: #b5c2e3;
      }
      @media (max-width: 1320px) and (min-width: 992px) {
        width: 80%;
      }
      @media (max-width: 678px) {
        width: 100%;
        height: 46px;
      }
    }
    button {
      @include button($button-bg2, $light-color);
      height: 56px;
      border-radius: 7px;
      padding: 20px 34px;
      &:hover {
        background-color: $light-color;
        color: $main-color !important;
        box-shadow: 0 0 9px 0px #00000024;
      }
      &:focus-visible {
        outline: none !important;
      }
      @media (max-width: 600px) {
        margin-top: 12px;
      }
    }
  }

  .header-rightside {
    position: relative;
    height: 100%;
    width: 100%;
    @media (max-width: 991px) {
      height: 468px;
      margin-top: 108px !important;
      // width: 50%;
      margin: auto;
    }
    @media (max-width: 600px) {
      height: 270px;
      margin-top: 86px !important;
      // width: 100%;
    }
    .header-img {
      position: absolute;
      .bg-header-img {
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .header-img1 {
      top: 0;
      right: 298px;
      width: 506.37px;
      height: 300.4px;
      animation: animation1 40s infinite;
      overflow: hidden;
      @media (max-width: 1920px) {
        animation: animation12 40s infinite;
        width: 341.42px;
        height: 190.58px;
        right: 194px;
      }
      @media (max-width: 600px) {
        animation: animation123 40s infinite;
        width: 170.42px;
        height: 110.58px;
      }
      .bg-header-img1 {
        width: 506.37px;
        height: 300.4px;
        @media (max-width: 1920px) {
          width: 341.42px;
          height: 190.58px;
        }
        @media (max-width: 600px) {
          width: 170.42px;
          height: 110.58px;
        }
      }
    }
    .header-img2 {
      top: 12%;
      right: 90px;
      width: 243.06px;
      height: 293.1px;
      animation: animation3 40s infinite;
      overflow: hidden;
      @media (max-width: 1920px) {
        right: 80px;
        width: 184.26px;
        height: 222.19px;
        animation: animation32 40s infinite;
      }
      @media (max-width: 600px) {
        animation: animation323 40s infinite;
        width: 128.26px;
        height: 106.19px;
      }
      .bg-header-img2 {
        width: 243.06px;
        height: 293.1px;
        @media (max-width: 1920px) {
          width: 184.26px;
          height: 222.19px;
        }
        @media (max-width: 600px) {
          width: 128.26px;
          height: 106.19px;
        }
      }
    }
    .header-img3 {
      right: 298px;
      top: 279.4px;
      width: 322.88px;
      height: 348.91px;
      animation: animation2 40s infinite;
      overflow: hidden;
      @media (max-width: 1920px) {
        top: 156px;
        right: 222px;
        width: 244.77px;
        height: 262.29px;
      }
      @media (max-width: 600px) {
        top: 90px;
        right: 98px;
        width: 144.77px;
        height: 140.29px;
      }
      .bg-header-img3 {
        width: 322.88px;
        height: 348.91px;
        @media (max-width: 1920px) {
          width: 244.77px;
          height: 262.29px;
        }
        @media (max-width: 600px) {
          width: 144.77px;
          height: 140.29px;
        }
      }
    }
    span {
      position: absolute;
      bottom: 0;
      right: 1%;
      width: 121.88px;
      height: 72.36px;
      img {
        width: 100%;
        height: 100%;
      }
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
  @media (max-width: 992px) {
    height: auto;
  }
  @media (max-width: 600px) {
  }
  @media (max-width: 375px) {
  }
}
.new-project-items.all {
  display: block !important;
  @media (max-width: 991px) and (min-width: 600px) {
    display: none !important;
  }
}
.new-project-items.tablet {
  display: none !important;
  @media (max-width: 991px) and (min-width: 600px) {
    display: block !important;
  }
}
.new-project-section {
  padding-top: 60px;
  padding-bottom: 40px;
  background: #eff3ff;
  @extend .flex-basic;
  @media (max-width: 600px) {
    padding-top: 0px;
  }

  .new-project-title {
    text-align: left;
    h2 {
      // margin: 55px 0;
      @media (max-width: 600px) {
        margin: 40px 0;
        text-align: center;
      }
      @media (max-width: 991px) {
        text-align: center;
      }
    }
    img {
      width: auto;
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
  .new-project-items {
    @extend .flex-basic;
    height: 100%;
    align-items: inherit;
    .row > * {
      padding: 0px 6px;
    }

    .new-project-item {
      a {
        text-decoration: none;
      }
      .card {
        @extend .card-basic;
        // @media (max-width: 1400px) and (min-width: 1024px) {
        //   padding: 8px 10px !important;
        //   p {
        //     font-size: 12px !important;
        //     span {
        //       font-size: 12px !important;
        //     }
        //   }
        // }
        @media (max-width: 327px) {
          padding: 28px 10px;
        }
      }

      .new-item-progass {
        @extend .progress;
      }
    }
  }

  button {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.06em;
    color: #000f3c;
    border: none;
    background: transparent;
    display: flex;
    margin-left: auto;
    justify-content: right;
    align-items: center;
    margin-top: 30px;
    span {
      width: 56px;
      height: 56px;
      display: flex;
      background: #5780ff;
      box-shadow: 0px 4px 100px 10px rgb(0 0 0 / 5%);
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-left: 14px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    @media (max-width: 675px) {
      margin-right: auto;
    }
  }
}

.upcoming-projects {
  padding: 100px 0;
  background: linear-gradient(97.41deg, #e4ebff -8.24%, $light-color 62.01%);
  @media (max-width: 600px) {
    padding: 60px 0;
  }
  .up-project {
    margin: 40px 0;
    a {
      text-decoration: none;
    }
    .card {
      @extend .card-basic;
      p.count.active {
        background: #fff;
        color: #000f3c;
      }
      padding: 0 0;
      .up-p-card-header {
        background: #e3eafe;
        width: 100%;
        @extend .flex-basic;
        padding: 20px 0;
        h4 {
          margin: 9px 0;
        }
      }
      .up-p-card-body {
        padding: 28px 34px;
        @media (max-width: 327px) {
          padding: 22px 0px;
        }
      }
    }
  }
}

.recent-project {
  padding: 100px 0;
  background-color: $light-color;
  @media (max-width: 600px) {
    padding: 55px 0;
  }
  .recent-project-table {
    margin-top: 40px !important;
    max-width: 975px;

    background: #f9f9f9;
    box-shadow: 0px 4px 20px 10px rgba(87, 128, 255, 0.15);
    border-radius: 3px;
    margin: auto;
    padding: 18px 0;
    table {
      width: 100%;
      thead {
        tr {
          th {
            padding: 22px 36px;
            font-weight: 500;
            font-size: 13px;
            text-transform: uppercase;
            color: #000f3c;
            @media (max-width: 675px) {
              display: none;
              padding: 2px 6px;
              font-size: 12px;
              text-align: center;
              &:first-child {
                display: table-cell !important;
              }
              &:last-child {
                display: table-cell !important;
              }
            }
          }
        }
      }
      tbody {
        tr {
          transition: all 0.4s ease-in-out;
          cursor: pointer;
          &:hover {
            background: #edf1ff !important;
          }
          td {
            font-weight: 600;
            font-size: 15px;
            text-transform: uppercase;
            color: #000f3c;
            div {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
            }
            img {
              width: 41px;
            }
            padding: 8px 33px;
            button.main-btn {
              @media (max-width: 600px) {
                width: 65%;
              }
              @include button($light-color, #000f3c);
              margin-left: 0px;
              box-shadow: 0px 4px 10px 5px rgba(87, 128, 255, 0.1);
              border-radius: 5px;
              width: 100%;
              &:hover {
                color: #000f3c;
                background: $light-color;
              }
            }
            button.main-btn.increase {
              @include button(#5780ff, $light-color);
              &:hover {
                background: #5780ff;
                color: $light-color;
              }
            }
            button.main-btn.dicrease {
              @include button($button-bg2, $light-color);
              &:hover {
                background: $button-bg2;
                color: $light-color;
              }
            }
            @media (max-width: 991px) {
              padding: 8px 6px;
            }
            @media (max-width: 675px) {
              display: none;
              padding: 8px 6px;
              font-size: 12px;
              text-align: center;
              &:first-child {
                display: table-cell !important;
              }
              &:last-child {
                display: table-cell !important;
              }
            }
          }
        }
      }
    }
    .recent-project-img-wrapper {
      width: 52px;
      height: 52px;
      background-color: #f5f5f5;
      border-radius: 50%;
      display: flex;
      align-content: center;
      justify-content: center;
      margin-right: 15px;

      img {
        max-width: 30px;
        max-height: 30px;
      }
    }

    @media (max-width: 992px) {
      margin: auto 20px;
    }
    @media (max-width: 600px) {
      width: 282px;
      width: auto;
    }
    @media (max-width: 285px) {
      width: auto;
    }
  }
}
.model-section {
  @extend .flex-basic;
  padding: 100px 0;
  background: linear-gradient(98.32deg, #e4ebff 3.71%, #ffffff 94.89%);
  @media (max-width: 600px) {
    padding: 60px 0;
  }
  img {
    width: inherit;
  }

  .model-section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .model-img {
    position: relative;
    img {
      &:last-child {
        position: absolute;
        width: 52px;
        height: 52px;
        top: 42%;
        left: 34%;
        transform: translate(-50%, -50%);
      }
    }
  }
  button.main-btn {
    background: $button-bg2;
    color: $light-color;
    margin-top: 24px;
    padding: 20px 34px;
    &:hover {
      background-color: #ffffff;
      color: #09216a !important;
      box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.1411764706);
    }
    @media (max-width: 600px) {
      padding: 16px 17px;
      margin-top: 20px;
    }
  }
  h2 {
    margin-top: 38px;
    font-size: 40px;
  }
  P {
    font-weight: 300;
    font-size: 25px;
    color: #000f3c;
    margin-top: 15px;
    @media (max-width: 600px) {
      font-weight: 300;
      font-size: 18px;
      line-height: 20px;
      color: #000f3c;
      text-align: center;
    }
  }
}

.pool-alert {
  padding: 80px 0;
  background: #09216a;

  h1 {
    font-weight: 600;
    font-size: 40px;
    color: #ffffff;
  }
  p {
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    width: 70%;
    margin-top: 10px;
    @media (max-width: 375px) {
      width: auto;
    }
  }
  button.main-btn {
    background: #ff2853;
    border-radius: 7px;
    margin-top: 20px;
    padding: 20px 34px;
    &:hover {
      background-color: #ffffff;
      color: #09216a !important;
      box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.1411764706);
    }
  }
  .col-md-6 {
    flex-direction: column;
  }
  .pool-alert-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    img.img1 {
      width: 333.5px;
      @media (max-width: 500px) {
        width: 223.6px;
      }
      @media (max-width: 300px) {
        width: 178px;
      }
    }
    img.img2 {
      width: 256.14px;
      margin-left: 150px;
      margin-top: -95px;
      @media (max-width: 991px) {
        width: 233.73px;
        margin-left: 120px;
        margin-top: -74px;
      }
      @media (max-width: 500px) {
        width: 171.73px;
        margin-left: 120px;
        margin-top: -74px;
      }
      @media (max-width: 300px) {
        width: 150px;
        margin-left: 100px;
        margin-top: -55px;
      }
    }

    @media (max-width: 992px) {
      margin-top: 70px;
      text-align: center;
    }

    @media (max-width: 600px) {
      img {
        max-width: 295.6px;
      }
    }
  }
}

.footer {
  padding: 66px 0 82px 0;
  background-color: #09216a;
  color: #ffffff;
  // background: linear-gradient(90.13deg, #e5ecff -15.4%, #ffffff 48.07%);
  .footer-logo {
    margin-top: -15px;
    img {
      width: 80%;
    }
    @media (max-width: 767px) {
      img {
        width: 160px;
        height: 38px;
      }
    }
  }
  .footer-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    .footer-menu-title {
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff;
      text-transform: capitalize;
    }
    .footer-menu-item {
      a {
        text-decoration: none;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        text-transform: capitalize;
        color: #ffffff;
        margin: 5px 0;
        display: inline-block;
      }
    }
    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }
}

.counter-section {
  background: linear-gradient(101.01deg, #d3deff -21.7%, #ffffff 46.47%);

  padding-top: 170px;
  padding-bottom: 85px;
  .counter-section-leftside {
    img {
      width: 150px;
    }
    h2 {
      font-weight: 600;
      font-size: 35px;
      color: #000f3c;
      @media (max-width: 600px) {
        font-weight: 600;
        font-size: 30px;
        line-height: 30px;
      }
    }
    p {
      font-weight: 300;
      font-size: 19px;
      line-height: 30px;
      color: #000f3c;
      @media (max-width: 600px) {
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
  .counter-card {
    // background: $light-color;
    // box-shadow: 0px 4px 20px 10px rgba(87, 128, 255, 0.12);
    // border-radius: 10px;
    @extend .flex-basic;
    padding: 25px 0;
    p {
      font-weight: 500;
      font-size: 20px;
      color: #09216a;
    }
    small {
      margin: 14px 0;
      background: #eef2fe;
      border-radius: 10px;
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #09216a;
      width: 237px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 35px;
    }
    .count-items {
      @extend .flex-basic;
      flex-direction: row;
      .count-item {
        margin: 0 16px;
        text-align: center;
        @media (max-width: 375px) {
          margin: 0 8px;
        }
        h4 {
          background: #eef2fe;
          border-radius: 3px;
          font-weight: 600;
          font-size: 25px;
          color: #09216a;
          width: 73px;
          height: 52px;

          @extend .flex-basic;
          @media (max-width: 375px) {
            padding: 9px;
            font-size: 18px;
            width: 55px;
            height: 41px;
          }
        }
        span {
          font-weight: 400;
          font-size: 15px;
          color: #909fcc;
        }
      }
    }
    .count-items.desable {
      h4 {
        color: #7284bb;
      }
    }

    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }
  .couter-progass {
    margin-top: 14px;
    p {
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
    }
    h3 {
      font-weight: 500;
      font-size: 18px;
      color: #000f3c;
    }
    .new-item-progass {
      background: #eef2fe;
      @extend .progress;
    }
  }
  .token-purchase {
    background: $light-color;
    box-shadow: 0px 4px 20px 10px rgba(87, 128, 255, 0.12);
    border-radius: 10px;
    @extend .flex-basic;
    padding: 40px 25px;
    .amount-of-tokens {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      label {
        font-weight: 400;
        font-size: 14.5px;
        line-height: 28px;
        color: #000f3c;
      }
      input {
        padding: 10px 15px;
        height: 48px;
        background: #ffffff;
        border-radius: 5px;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        width: 122px;
        &:focus {
          outline: none;
          background: #d0dbfb;
        }
      }

      span {
        width: 13px;
        height: 13px;
        background: #d0dbfb;
        border-radius: 50%;
        text-indent: 100%;
        overflow: hidden;
        display: inline-block;
        position: absolute;
        top: 57%;
        left: 15px;
        @media (max-width: 600px) {
          left: 26%;
        }
        @media (max-width: 480px) {
          left: 10%;
        }
        @media (max-width: 375px) {
          left: -2%;
        }
      }
    }
    button.main-btn {
      background: #ff2853;
      text-transform: capitalize;
      width: 153px;
      text-align: center;
      &:hover {
        background: #ffffff;
        box-shadow: 0px 0px 5px 3px rgba(88, 128, 255, 0.15);
        color: #08216e !important;
      }
    }
    // .amount-of-tokens.USTD {
    // }

    .balance {
      .balance-header {
        position: relative;
        p {
          font-weight: 700;
          font-size: 14.5px;
          line-height: 28px;
          color: #000f3c;
          width: 100%;
          text-align: center;
          margin-bottom: 7px;
        }
        img.reload-img {
          width: 15.99px;
          height: 16px;
          position: absolute;
          top: -15%;
          left: 92%;
          cursor: pointer;
        }
      }
      a {
        text-decoration: none;
        width: 100%;
        max-width: 245px;
        height: 74px;
        background: #2d4798;
        border-radius: 10px;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        @media (max-width: 375px) {
          height: 45px;
          font-size: 12px;
        }
      }
      p {
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        color: #09216a;
        img {
          width: 15.73px;
          height: 14.68px;
        }
      }
    }
  }
}

.overview {
  background: #f5f8ff;
  padding: 80px 0;
  .overview-leftside {
    h4 {
      font-weight: 500;
      font-size: 20px;
      margin-top: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-decoration-line: underline;

      color: #000f3c;
    }
    p {
      font-size: 19px;
      margin-top: 50px;
      font-weight: 300;
      line-height: 30px;
      display: flex;
      align-items: center;

      color: #000f3b;
    }
  }
  .pool-details {
    background: #2d4798;
    box-shadow: 0px 4px 20px 10px rgba(87, 128, 255, 0.12);
    border-radius: 10px;

    padding: 28px 45px;
    @media (max-width: 600px) {
      padding: 28px 15px;
    }
    h5 {
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-decoration-line: underline;
      color: #ffffff;
    }
    h4 {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-top: 30px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        margin-top: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $light-color;
        border-bottom: 1px solid rgba(0, 15, 60, 0.2);
        @media (max-width: 600px) {
          font-size: 13px;
        }
        p {
          &:last-child {
            margin-left: 109px;
            text-align: right;
            @media (max-width: 375px) {
              margin-left: 50px;
            }
          }
        }
      }
    }
  }
}

.token-purchase-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: left;
  @media (max-width: 676px) {
    text-align: center;
  }
}
.form-background {
  background: #f2f5ff;
}
.form-site-divide {
  display: grid;
  grid-template-columns: 30% 70%;
}
.onboarding {
  padding-top: 150px;
  padding-bottom: 100px;
  background: #fff;
  padding-left: 30px;
  padding-right: 25px;
  .onbarding-heading {
    // margin-bottom: 25px;
    h3 {
      font-weight: 600;
      font-size: 25px;
      line-height: 25px;
      color: #000f3c;
    }
    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      color: #9aacdc;
      margin-top: 10px;
    }
  }
  .onbarding-items {
    list-style: none;
    .onbarding-item {
      height: 50px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 15px;
      margin-top: 25px;
      position: relative;
      z-index: 9;
      &::after {
        position: absolute;
        content: "";
        top: 100%;
        left: 10%;
        height: 58px;
        width: 5px;
        border-left: 2px dashed #c9d8ff;
        z-index: -1;
      }
      img {
        width: 39px;
        height: 39px;
      }
      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        color: #9aacdc;
        margin-left: 10px;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    .onbarding-item.active {
      background: #2d4798;
      span {
        font-weight: 500;
        color: #ffffff;
      }
    }
    .onbarding-item.done {
      background: transparent;
      span {
        font-weight: 500;
        color: #000f3c;
      }
    }
  }
}
.contact-form {
  padding-bottom: 40px;
  margin-top: 150px;
  margin-bottom: 100px;
  // background: #ffffff;
  // box-shadow: 0px 4px 20px 10px rgba(87, 128, 255, 0.12);
  border-radius: 10px;
  @extend .flex-basic;
  .modal-header-wrapper {
    display: flex;
    justify-content: center;
  }

  h2 {
    font-weight: 700;
    font-size: 35px;
    line-height: 35px;
    color: #000f3c;
    @media (max-width: 992px) {
      width: 100% !important;
    }
    @media (max-width: 450px) {
      font-size: 22px;
    }
  }
  .form-group {
    margin: 5px 0;
    @media (max-width: 992px) {
      width: 100%;
      padding: 0 60px;
    }
    @media (max-width: 450px) {
      padding: 0 20px;
    }
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #000f3c;
      margin-bottom: 10px;
    }
    label {
      img {
        margin-right: 7px;
        width: 19px;
        height: 19px;
      }
      font-weight: 500;
      font-size: 15px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.035em;
      color: #000f3c;
      span {
        color: #ff2853;
      }
    }
    input,
    select {
      background: #ffffff;
      border: 1px solid #e2e1e5;
      box-sizing: border-box;
      border-radius: 7px;
      font-weight: 400;
      font-size: 17px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #000f3c !important;
      padding: 6px 22px;
      width: 100%;
      &::placeholder {
        color: #d2d2d2;
      }
      &:focus {
        outline: none;
        border: 1px solid #2d4798;
      }
      @media (max-width: 430px) {
        width: inherit;
        padding: 6px;
      }
    }

    textarea {
      background: #ffffff;
      border: 1px solid #e2e1e5;
      box-sizing: border-box;
      border-radius: 7px;
      font-weight: 400;
      font-size: 17px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #000f3c !important;
      padding: 6px 22px;
      width: 724px;
      height: 128px;
      @media (max-width: 992px) {
        width: 100%;
      }
      &::placeholder {
        color: #d2d2d2;
      }
      &:focus {
        outline: none;
        border: 1px solid #2d4798;
      }
      @media (max-width: 430px) {
        width: inherit;
      }
    }

    .incorrect {
      border: 1px solid #ff2853;
    }

    .input-number {
      input {
        padding-left: 122px;
      }
      position: relative;
      .number-dropdon {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 0;
        height: 100%;
        width: auto;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        left: 4%;
        .input-country-value {
          display: flex;
          width: 69px;
          justify-content: space-between;
          align-items: center;
          img {
            width: 30px;
          }
          span {
            margin: 0;
            margin-left: 10px;
          }
          i {
            width: 13px;
            margin-top: 4px;
            margin-left: 7px;
            img {
              width: 100%;
            }
          }
        }
        ul {
          list-style: none;
          background: #fff;
          padding: 18px 14px;
          box-shadow: 0 0 30px 0 #00000042;
          position: absolute;
          top: 100%;
          left: 0;
          width: 150px;
          height: 240px;
          overflow-y: scroll;
          li {
            display: flex;
            justify-content: space-around;
            padding: 12px 0;
            border-bottom: 1px solid #e1e1e1;
            cursor: pointer;
            img {
              width: 30px;
            }
          }
        }
      }
    }

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #09216a;
      display: flex;
      justify-content: end;
      align-items: center;
      margin-top: 4px;
      img {
        width: 19px;
        height: 19px;
      }
    }
  }
  .social-lick {
    width: 724px;
    @media (max-width: 992px) {
      width: 100%;
      padding: 0 60px;
      .form-group {
        width: 100%;
        padding: 0 0;
      }
    }
    @media (max-width: 450px) {
      padding: 0 20px;
    }
    h5 {
      font-weight: 600;
      font-size: 15px;
      line-height: 30px;
      letter-spacing: 0.035em;
      color: #000f3c;
    }
    input,
    select {
      width: 100%;
    }
    .upload-pitch-deck {
      label {
        font-weight: 500;
        font-size: 15px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: 0.035em;
        color: #000f3c;
        span {
          color: #ff2853;
        }
      }
      h4 {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #6d80b1;
      }
      .input-file {
        // width: 157px;
        height: 52px;

        position: relative;
        margin: 20px 0;
        overflow: hidden;
        input {
          opacity: 0;
          height: 100%;
          position: absolute;
          top: 0;
          right: 35%;
          cursor: pointer;
        }
        button {
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 157px;
          height: 40px;
          background: #2d4798;
          border-radius: 10px;
          font-weight: 600;
          font-size: 14px;
          line-height: 14px;
          color: #ffffff;
          border: none;
          transition: all 0.4s ease-in-out;
          margin: 5px 12px;
        }
        &:hover {
          button {
            background: #ffffff;
            box-shadow: 0px 0px 5px 3px rgba(88, 128, 255, 0.15);
            border-radius: 10px;
            color: #08216e;
          }
        }
      }
      p {
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
        color: #bfc6d8;
      }
    }
    .drag-drop-file {
      height: 132px;
      background: linear-gradient(0deg, #f9faff, #f9faff), #f0f3ff;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      img {
        width: 24px;
        height: 24px;
      }
      p {
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        color: #09216a;
        margin-top: 10px;
      }
      input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }

  .label-tootip {
    position: relative;
    &:hover {
      .label-tootip-card {
        display: block;
      }
    }
    .label-tootip-card {
      position: absolute;
      top: -245%;
      left: -55%;
      width: 223px;
      background: #ffffff;
      box-shadow: 0px 0px 5px 3px rgba(88, 128, 255, 0.15);
      border-radius: 10px;
      padding: 15px 17px;
      z-index: 9;
      display: none;
      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
      }
      &::after {
        position: absolute;
        content: "";
        left: 6%;
        bottom: -23%;
        background: #fff;
        height: 15px;
        width: 22px;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
        z-index: -1;
      }
    }
  }

  button.main-btn {
    width: 189px;
    height: 48px;
    background: #ff2853;
    border-radius: 10px;
    color: #fff;
    &:hover {
      background-color: #ffffff;
      color: #09216a !important;
      box-shadow: 0px 0px 5px 3px rgba(88, 128, 255, 0.15);
    }
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
  }
  button.back-btn {
    border: none;
    background: transparent;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: #09216a;
    a {
      color: #09216a;
      &:hover {
        color: #09216a;
      }
    }
    img {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }
}

.modal-section {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 120px;
  background: linear-gradient(90deg, #e5edff 3.32%, #ffffff 98.32%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 600px) {
    height: 265px;
  }

  .modal-info {
    display: flex;
    align-items: center;
    height: 120px;
    width: 84%;
    margin: auto;
    @media (max-width: 992px) {
      width: 95%;
    }
    @media (max-width: 600px) {
      height: 265px;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 40px;
    }
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #000f3c;
      width: 64%;

      a {
        color: #000f3c;
      }
      @media (max-width: 992px) {
        font-size: 12px;
      }
      @media (max-width: 600px) {
        width: 100%;
      }
    }
    div {
      display: flex;
      align-items: center;
      height: 100%;
      width: 30%;
      margin-left: auto;
      justify-content: space-evenly;
      @media (max-width: 992px) {
        width: 36%;
      }
      @media (max-width: 600px) {
        width: 100%;
        height: auto;
      }
      button.namage {
        border: none;
        background: transparent;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-transform: lowercase;
        color: #000f3c;
      }
      button.main-btn {
        @include button($button-bg2, $light-color);
      }
      span {
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          &:last-child {
            display: none;
          }
          @media (max-width: 992px) {
            display: block !important;
            &:first-child {
              display: none !important;
            }
          }
          @media (max-width: 600px) {
            position: absolute;
            top: 5%;
            right: 5%;
          }
        }
      }
    }
  }
}

.from-pagination {
  background: #f7f8ff;
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 104px;
  ul {
    list-style: none;
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
    width: 100%;
    li {
      width: 38px;
      height: 38px;
      background: #edf2ff;
      box-shadow: 0px 0px 4px #c7d6ff;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #09216a;
      border-radius: 50%;
      position: relative;
      margin-right: 56px;
      &:last-child {
        &::before {
          position: absolute;
          content: none;
          width: 0 !important;
          height: 0 !important;
        }
      }
      &::before {
        position: absolute;
        content: "";
        width: 56px;
        height: 4px;
        background: #edf2ff;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.form-check {
  display: flex;
  align-items: center;
  input {
    width: 18px !important;
    padding: 0 !important;
    background: inherit;
  }
  input:checked {
    background-color: #0d6efd;
  }
  label {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 14px !important;
    color: #000f3c !important;
    margin-left: 12px;
    margin-top: 4px;
  }
}

.data-processing {
  background: #09216a;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    height: 265px;
    width: 265px;
  }
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: #ffffff;
    margin-top: -92px;
    margin-bottom: 12px;
  }
  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }
}

.congratulatjions {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f0f4ff;
  img {
    width: 42px;
    height: 67px;
  }
  h2 {
    font-weight: 600;
    font-size: 35px;
    line-height: 35px;
    text-align: center;
    color: #000f3c;
    margin-top: 20px;
    @media (max-width: 475px) {
      font-size: 28px;
    }
  }
  h5 {
    margin-top: 20px;
    font-weight: 500;
    font-size: 23px;
    line-height: 23px;
    text-align: center;
    color: #000f3b;
  }
  p {
    width: 448px;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    margin-top: 8px;
    color: #000f3b;
    @media (max-width: 457px) {
      width: auto;
    }
  }
  button.main-btn {
    @include button($button-bg2, $light-color);
    background: #ff2853;
    margin-top: 20px;
    &:hover {
      background: #ffffff;
      box-shadow: 0px 0px 5px 3px rgba(88, 128, 255, 0.15);
      color: #08216e !important;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}

// scbscribe modal
.popup-overlay {
  animation: fadeIn 0.7s ease-in;
  background: rgba(0, 0, 0, 0.5);
}
.popup-overlay.fadeOut-overlay {
  animation: fadeOut 0.7s ease-out;
}

@keyframes fadeIn {
  from {
    background: rgba(0, 0, 0, 0);
    opacity: 0;
  }
  to {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
  }
  to {
    background: rgba(0, 0, 0, 0);
    opacity: 0;
  }
}

.popup-content {
  height: 560px;
  border: none !important;
  overflow: hidden;
  border-radius: 10px;
  background: #edf2ff !important;
  box-shadow: 0px 4px 20px 10px rgb(87 128 255 / 12%);
  width: 486px !important;

  @media (max-width: 992px) {
    width: 60% !important;
  }
  @media (max-width: 768px) {
    width: 80% !important;
  }
  @media (max-width: 580px) {
    height: 95%;
  }
}

.modal {
  font-size: 12px;
  position: absolute;
  display: block;
}

.contact-form.modal-form {
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: none;
  border-radius: 0;
  height: 100%;
  color: #09216a;
  h2 {
    @media (max-width: 580px) {
      font-weight: 700;
      font-size: 30px;
      line-height: 30px;
      text-align: center;
    }
  }
  .form-group .input-number .number-dropdon {
    .input-country-value {
      span {
        margin-left: 5px;
      }
      i {
        width: 9px;
        margin-left: 4px;
      }
    }
    ul {
      position: absolute;
      top: 100%;
      left: -19%;
      width: 150px;
      height: 240px;
      @media (max-width: 580px) {
        top: -512%;
        left: -13%;
        width: 107px;
        height: 200px;
      }
    }
  }
  .form-group {
    width: 80%;

    input {
      color: #20336a;
      &::placeholder {
        color: #20336a;
      }
      @media (max-width: 580px) {
        width: 100%;
        padding-right: 0;
        font-size: 14px;
      }
    }
    label {
      color: #9aacdc;
    }
    .input-number {
      input {
        @media (max-width: 580px) {
          padding-left: 107px;
        }
      }
    }
    @media (max-width: 992px) {
      padding: 0 0;
    }
    @media (max-width: 580px) {
      margin: 0px auto;
      margin-top: 28px !important;
    }
  }
  button.main-btn {
    @media (max-width: 580px) {
      width: 168px;
      height: 48px;
      background: #ff2853;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 45px !important;
    }
  }
}

.close-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 60%;
  margin: 0 auto;
  img {
    width: 132px;
    height: 132px;
    border-radius: 50%;
  }
  h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    color: #09216a;
    margin-top: 20px;
  }
  p {
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #20336a;
  }
  button {
    width: 168px;
    height: 48px;
    background: #ff2853;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 45px !important;
  }
}

.modal > .modal-title {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 20px;
  top: 12px;
  font-size: 42px;
  background: transparent;
  border-radius: 18px;
  border: 0;
  &:focus-visible {
    outline: none !important;
  }
}
button,
button.main-btn {
  &:focus-visible {
    outline: none !important;
  }
}

.rangeslider {
  background: #eef2fe !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 22px !important;
  height: 22px !important;
  z-index: 9;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #ff2853 !important;
}

.slider {
  position: relative;
  .main-value {
    position: absolute;
    top: -21%;
    left: 97%;
    background: #fff;
    height: 18px;
    width: 18px;
    box-shadow: 0 0 2px 0 rgb(17 17 17 / 77%);
    display: inline-block;
    border-radius: 50%;
    &:hover {
      span {
        opacity: 1;
        visibility: visible;
      }
    }
    span {
      position: absolute;
      bottom: 198%;
      right: 0;
      background: #fff;
      padding: 10px 10px;
      box-shadow: 0 0 8px 0px #2d479859;
      border-radius: 7px;
      transition: all 0.3s ease-in-out;
      z-index: 9;
      opacity: 0;
      visibility: hidden;
      &::before {
        content: "";
        background: #fff;
        box-shadow: 0 0 8px 0px #2d479859;
        height: 20px;
        width: 20px;
        position: absolute;
        right: 0;
        top: 90%;
        clip-path: polygon(0 0, 100% 0, 63% 92%);
        z-index: 8;
      }
    }
  }
}

.rangeslider .rangeslider__handle-tooltip {
  text-align: center;
  position: absolute;
  background-color: #fff !important;
  font-weight: normal;
  font-size: 14px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: #111 !important;
  padding: 10px 10px;
  width: auto !important;
  height: auto !important;
  box-shadow: 0 0 8px 0px #2d479859;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff !important;
  left: 50%;
  bottom: -8px;
  transform: translate3d(-50%, 0, 0);
}
.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #fff !important;
  left: 100%;
  top: 12px;
}

.closed-card {
  background: #f7f9ff;
  box-shadow: 0px 4px 20px 10px rgba(87, 128, 255, 0.12);
  border-radius: 10px;
  img {
    height: 115px;
    width: 100%;
  }
  .total-earning {
    padding: 5px 20px;
    h2 {
      font-weight: 300;
      font-size: 23px;
      color: #09216a;
    }
    p {
      font-weight: 700;
      font-size: 20px;
      color: #09216a;

      span {
        font-weight: 400;
        font-size: 20px;
        color: #09216a;
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  ul {
    list-style: none;
    padding: 5px 20px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 16px 0;

      p {
        font-weight: 500;
        font-size: 16px !important;
        color: #09216a;
      }
      span {
        font-weight: 700;
        font-size: 18px !important;
        color: #09216a;
      }
    }
  }
}

.header-search.about-header-search {
  flex-direction: row !important;
  @media (max-width: 992px) {
    flex-direction: column !important;
    text-align: center;
  }
  @media (max-width: 600px) {
  }
  input {
    @media (max-width: 991px) {
      margin: auto;
      text-align: center;
    }
  }
  button {
    @media (max-width: 1280px) {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      padding: 0px 0;
      width: 153px;
      height: 58px;
    }
    @media (max-width: 991px) {
      margin: auto;
      text-align: center;
    }
  }
}

.header-leftside.about-header-leftside {
  justify-content: flex-start;
  margin-left: 0 !important;
}

.features {
  background: #f5f8ff;
  padding: 80px 0 145px 0;
  h3 {
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    color: #000f3c;
    margin-bottom: 80px;
    @media (max-width: 1280px) {
      font-weight: 600;
      font-size: 30px;
      line-height: 30px;
    }
    @media (max-width: 991px) {
      text-align: center;
    }
  }
  .container-fluid {
    @media (max-width: 600px) {
      padding: 0 0;
    }
  }

  .features-card {
    display: flex;
    align-items: center;
    @media (max-width: 1280px) {
    }
    @media (max-width: 991px) {
      display: block;
      text-align: center;
    }
    .card-icon {
      img {
        width: 88px;
        height: 88px;
        @media (max-width: 1280px) {
          width: 60px;
          height: 60px;
        }
      }
      @media (max-width: 1280px) {
      }
      @media (max-width: 991px) {
        text-align: center;
      }
    }
    .card-dec {
      margin-left: 30px;
      @media (max-width: 1280px) {
      }
      @media (max-width: 991px) {
        margin-left: 0;
        margin-top: 20px;
      }
      h5 {
        font-weight: 600;
        font-size: 27px;
        line-height: 27px;
        color: #000f3c;
        @media (max-width: 1280px) {
          font-weight: 600;
          font-size: 20px;
          line-height: 20px;
        }
      }
      p {
        margin-top: 12px;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #000f3c;
        @media (max-width: 1280px) {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }
        @media (max-width: 991px) {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}
.show-time {
  background: #e7edff;
  padding: 80px 0;
  .show-time-card {
    img {
      width: 62px;
      height: 62px;
    }
    h3 {
      font-weight: 600;
      font-size: 50px;
      line-height: 35px;
      text-align: center;
      color: #000f3c;
      margin-top: 20px;
    }
    p {
      font-weight: 400;
      font-size: 23px;
      color: #000f3c;
      margin-top: 18px;
      line-height: 32px;
    }
  }
}

.community {
  padding: 120px 0;
  background: #09216a;
  position: relative;
  z-index: 9;
  @media (max-width: 675px) {
    padding-bottom: 250px;
  }
  .community-bg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 716px;
    background-repeat: no-repeat;
    pointer-events: none;
    // background-size: cover;
    z-index: -1;
    @media (max-width: 1280px) {
      display: none;
    }
  }
  .community-bg1 {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 476px;
    pointer-events: none;
    background-repeat: no-repeat;
    // background-size: cover;
    display: none;
    @media (max-width: 1280px) and (min-width: 991px) {
      display: block;
    }
  }
  .community-bg2 {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
    display: none;
    @media (max-width: 991px) and (min-width: 675px) {
      display: block;
    }
  }
  .community-bg3 {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 50%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
    display: none;
    @media (max-width: 675px) {
      display: block;
    }
  }
  h3 {
    font-weight: 600;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 50px;
    color: #ffffff;
    @media (max-width: 1280px) {
      font-weight: 600;
      font-size: 30px;
      line-height: 30px;
    }
    @media (max-width: 991px) {
      font-weight: 600;
      font-size: 25px;
      line-height: 25px;
    }
  }
  h5 {
    font-weight: 600;
    font-size: 27px;
    line-height: 27px;
    color: #ffffff;
    @media (max-width: 1280px) {
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
    }
    @media (max-width: 991px) {
      font-weight: 600;
      font-size: 17px;
      line-height: 17px;
    }
  }
  ul {
    list-style: none;
    margin-top: 15px;
    li {
      padding: 0.3rem 0;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      display: flex;
      align-items: center;
      margin: 10px 0;
      img {
        width: 20.38px;
        height: 16.22px;
        @media (max-width: 1280px) {
          width: 13.25px;
          height: 10.54px;
        }
        @media (max-width: 991px) {
          width: 13.25px;
          height: 10.54px;
        }
      }
      p {
        margin-left: 5px;
        font-size: 20px;
        @media (max-width: 1280px) {
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
        }
        @media (max-width: 991px) {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
        @media (max-width: 678px) {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
  .header-search {
    width: 100%;
    @extend .flex-basic;
    width: 100%;
    justify-content: flex-start;
    margin-top: 32px;
    flex-direction: column;
    align-items: start;
    @media (max-width: 600px) {
      flex-wrap: wrap;
      margin-bottom: 5px;
      flex-direction: column;
      align-items: start;
    }
    input {
      @extend .input-basic;
      border: 1px solid #5780fe !important;
      border-radius: 8px !important;
      background: transparent !important;
      padding: 10px 18px !important;
      margin-bottom: 16px !important;
      height: 56px;
      color: #b5c2e3;
      &::placeholder {
        color: #b5c2e3;
      }
      @media (max-width: 1320px) and (min-width: 992px) {
        width: 80%;
      }
      @media (max-width: 678px) {
        width: 100%;
        height: 46px;
      }
    }
    button {
      @include button($button-bg2, $light-color);
      height: 56px;
      border-radius: 7px;
      width: 153px;
      height: 56px;
      &:hover {
        background-color: $light-color;
        color: $main-color !important;
        box-shadow: 0 0 9px 0px #00000024;
      }
      &:focus-visible {
        outline: none !important;
      }
      @media (max-width: 600px) {
        margin-top: 12px;
      }
    }
  }
}

.follow-us {
  padding-top: 58px;
  padding-bottom: 24px;

  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f5f8ff;
  @media (max-width: 991px) {
    height: 294px;
  }
  @media (max-width: 600px) {
    height: 363px;
  }
  h2 {
    font-weight: 600;
    font-size: 37px;
    line-height: 37px;
    text-align: center;
    color: #000f3b;
    @media (max-width: 1280px) {
      font-weight: 600;
      font-size: 25px;
      line-height: 25px;
    }
    @media (max-width: 991px) {
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
    }
    @media (max-width: 600px) {
      font-weight: 600;
      font-size: 25px;
      line-height: 25px;
      width: 261px;
      margin: auto;
      margin-bottom: 25px;
    }
  }
  .follow-icons {
    margin-top: 40px;
    img {
      width: 77px;
      height: 77px;
      margin: 20px;
      @media (max-width: 1280px) {
        width: 59.63px;
        height: 59.63px;
      }
      @media (max-width: 991px) {
        width: 47.52px;
        height: 47.52px;
      }
      @media (max-width: 600px) {
        width: 59.63px;
        height: 59.63px;
        margin: 4px 13px;
      }
    }
    @media (max-width: 600px) {
      max-width: 330px;
      margin: auto;
      text-align: center;
      margin-top: 0;
    }
  }
}

.account-tap {
  padding-top: 150px;
  background: #e7edff;
  .account-tap-heading {
    h3 {
      font-weight: 700;
      font-size: 50px;
      line-height: 50px;
      color: #000f3c;
      margin-bottom: 30px;
    }
  }
}

.tokens {
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  color: #000f3c;
  width: 129px;
  height: 48px;
  background: transparent;
  border-radius: 10px;
  border: none;
}
.tokens.active {
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  color: #ffffff;
  width: 129px;
  height: 48px;
  background: #ff2853;
  border-radius: 10px;
  border: none;
}

.token-table {
  box-sizing: border-box;
  margin-top: 30px;
  padding-bottom: 60px;
  background: #3c5ab5;
  @media (max-width: 991px) {
    overflow-x: scroll;
  }
  table {
    width: 100%;
    box-sizing: border-box;
    thead {
      background: #2d4798;
      tr {
        th {
          font-weight: 500;
          font-size: 17px;
          line-height: 40px;
          color: #ffffff;
          padding: 20px 40px;
          box-sizing: border-box;
          text-align: left;
        }
      }
    }
    tbody {
      background: #3c5ab5;
      tr {
        td {
          padding: 20px 40px;
          box-sizing: border-box;
          font-weight: 400;
          font-size: 19px;
          line-height: 40px;
          color: #ffffff;
          p {
            display: flex;
            justify-content: start;
            align-items: center;
          }
          img {
            width: 39px;
            height: 39px;
          }
          span {
            font-weight: 400;
            font-size: 19px;
            line-height: 40px;
            color: #ffffff;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.slider-nav {
  background: #031855;
  padding-top: 112px;
  @media (max-width: 600px) {
    padding-top: 55px;
  }
  .navbar-slider {
    height: 79px;
    @media (max-width: 600px) {
      height: 55px;
      padding-top: 16px;
    }
    display: flex;
    align-items: center;
    .nav-slider-item {
      display: flex;
      justify-content: start;
      align-items: center;
      img {
        width: 26px;
        height: 26px;
        @media (max-width: 600px) {
          width: 18px;
          height: 18px;
        }
      }
      .nav-slider-des {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
        margin-left: 5px;
        @media (max-width: 1280px) {
        }
        @media (max-width: 991px) {
        }
        @media (max-width: 750px) {
          font-weight: 600;
          font-size: 13px;
          line-height: 22px;
        }
        @media (max-width: 600px) {
          font-weight: 600;
          font-size: 15px;
          line-height: 22px;
        }
        @media (max-width: 375px) {
        }
      }

      .parsent {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 7px;
        @media (max-width: 1280px) {
        }
        @media (max-width: 991px) {
        }
        @media (max-width: 600px) {
        }
        @media (max-width: 375px) {
        }
        img {
          width: 13px;
          height: 14px;
          @media (max-width: 1280px) {
          }
          @media (max-width: 991px) {
          }
          @media (max-width: 600px) {
            width: 11px;
            height: 12px;
          }
          @media (max-width: 375px) {
          }
        }
        span {
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          margin-left: 3px;
          @media (max-width: 1280px) {
          }
          @media (max-width: 991px) {
          }
          @media (max-width: 600px) {
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
          }
          @media (max-width: 375px) {
          }
        }
      }
      .parsent.blue {
        span {
          color: #3c6aff;
        }
      }
      .parsent.red {
        span {
          color: #ff2853;
        }
      }
    }
  }
}

.swap-section {
  margin-top: 30px !important;
  padding: 0 112px;
  height: 312px;
  width: 545px;
  background: #09216a;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 20px 10px rgba(87, 128, 255, 0.12);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  img {
    width: 210px;
    height: 210px;
    margin-top: -74px;
  }
  .content-swap {
    margin-top: -65px;
    h4 {
      font-weight: 700;
      font-size: 25px;
      line-height: 25px;
      text-align: center;
      color: #ffffff;
    }
    h5 {
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      margin-top: 5px;
    }
  }
  h4 {
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
  }
  h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }
  @media (max-width: 600px) {
    height: 488px;
    width: 283px;
    padding: 0 25px;
  }
  margin: 0 auto;
}

.bg-for-social {
  padding: 5px 0;
  background: #e8edff;
  margin-top: 40px;
  width: 100%;
}

.delete-founder-btn {
  font-weight: 500;
  font-size: 14.5px;
  line-height: 28px;
  color: #000f3c;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-top: 22px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
